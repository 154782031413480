
export default {
  props: {
    sections: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Object,
      default: () => {},
    },
    hero: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      componentsMounted: 0,
    }
  },
  methods: {
    getComponent(component) {
      component = component.replace('Component', '')
      return component
    },

    setMounted() {
      this.componentsMounted++
      if (this.sections.length === this.componentsMounted) {
        this.$nextTick(() => this.$nuxt.$emit('pageMounted'))
      }
    },
  },
}
